import React from 'react';
import { navigate } from 'gatsby';

import { isBrowser } from '../utils/auth';

const App = () => {
	// redirect to client side routes as there is currently no non-authenticated content for the site
	if (isBrowser) {
		navigate('/app');
	}
	return null;
};

export const Head = () => <title>ARNG SMSP Support Tool</title>;

export default App;
